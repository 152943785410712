import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';
import { useStoreActions } from 'store/store';
import { checkLive } from 'utils/live-check';

import { DropDataType } from '../types';
import { GET_HOT_DROPS } from 'modules/library/graphql/Queries.graphql';

import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';

import LinneyImage from '../assets/LinneyBanner.jpg';
import ArtistCard from './components/ArtistCard';
import ErrorPage from 'modules/prereg/pages/ErrorPage';

import {
  CustomSpacer,
  HeadContainer,
  CardButton,
} from './styled/HotDropsPage.styled';
import { sm } from 'utils/constants';


const HotDropsPage = () => {
  const navigate = useNavigate();

  const viewport = useViewport();
  const setShowFCABanner = useStoreActions((state) => state.style.setShowFCABanner);
  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );
  const setIsHeaderTransparent = useStoreActions(
    (state) => state.style.setHeaderTransparent,
  );

  const [drops, setDrops] = useState<DropDataType[]>();

  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  const linneyPlaceholderData = {
    id: 'linney',
    header: 'Linney | Feeling This',
    date: '',
    image: LinneyImage,
    smallImage: LinneyImage,
    artistSlug: '',
    songSlug: '',
    songType: 'COMING_SOON',
    remainingBits: 1,
    soldBitsInverted: 1,
    live: false,
    explicitContent: false,
    bannerFocalPoint: 'LEFT',
  }

  const { data: hotDrops } = useQuery(GET_HOT_DROPS);
  
  useEffect(() => {
    setShowFCABanner(true)
    setMainFooterHidden(true);

    if (viewport.width <= sm) {
      setIsHeaderTransparent(false);
    }

    return () => {
      setShowFCABanner(false)
      setMainFooterHidden(false);

      if (viewport.width <= sm) {
        setIsHeaderTransparent(true);
      }
    };
  }, []);

  useEffect(() => {
    if (hotDrops && hotDrops.allReleases) {
      const dropData = hotDrops.allReleases.nodes;
      const dropList: DropDataType[] = [];
      const soldList: DropDataType[] = [];
      const soonList: DropDataType[] = [];
      const constList: DropDataType[] = [];

      dropData.forEach((item: any) => {

        const drop = item.songBySongId
        let image = ''

        const bitCount = JSON.parse(drop.bitCount)
        let remainingBits = bitCount.remaining_bits
        let soldBitsInverted = bitCount.sold_bits_inverted

        if (drop.slug === 'drop') {

          remainingBits = 0
          soldBitsInverted = 0
          
        }

        if (drop.fileByBannerId?.url) {
          image = drop.fileByBannerId?.url
        } else {
          image = drop.fileByCoverId.url
        }

        let songType = '';
        if (drop.songType) {
          songType = drop.songType;
        }
        
        const is_live = checkLive(drop.releaseAt);

        const data = {
          id: drop.id,
          header: `${drop.artistName} | ${drop.title}`,
          date: formatDate(drop.releaseAt),
          image: image,
          artistSlug: drop.artistProfileByArtistId.slug,
          songSlug: drop.slug,
          songType: songType,
          live: is_live.now_epoch > is_live.date_epoch,
          remainingBits: remainingBits,
          soldBitsInverted: soldBitsInverted,
          explicitContent: drop.explicitContent,
          bannerFocalPoint: drop.bannerFocalPoint,
        }

        /*
        if (soldBitsInverted === 0) {
          soldList.push(data);
        } else if (songType === 'COMING_SOON') {
          soonList.push(data);
        } else {
          dropList.push(data);
        }
        */

        dropList.push(data);
      });

      // Coming Soon Hardcoded
      constList.push(linneyPlaceholderData);
      //constList.push(bayStreetPlaceholderData);

      setDrops([...dropList, ...soonList, ...soldList, ...constList]);    
    }
  }, [hotDrops]);

  function formatDate(date: string) {
    const values = date.split('T')[0].split('-');
    const formatted = `${values[2]}|${values[1]}|${values[0].substring(2)}`;
    return formatted;
  }

  return (
    <>
      {drops ? (
        <PageContainer pageTitle={'Releases | SongBits'} reduceFooter={'0'}>
          <HeadContainer>
            <CustomSpacer />
            {drops.map((drop, index) => (
              <CardButton
                key={index}
                className={`artist-card-${index} release-list-item release-list-item-${drop.artistSlug}-${drop.songSlug}`}
                label={
                  <ArtistCard
                    key={drop.id}
                    header={drop.header}
                    date={drop.date}
                    image={drop.image}
                    live={drop.live}
                    songType={drop.songType}
                    soldBits={drop.soldBitsInverted}
                    explicit={drop.explicitContent}
                    remainingBits={drop.remainingBits}
                    smallImage={drop.smallImage || drop.image}
                    index={index}
                    bannerFocalPoint={drop.bannerFocalPoint}
                  />
                }
                onClick={() => {
                  navigateToHandler(`/${drop.artistSlug}/${drop.songSlug}`);
                }}
              />
            ))}            
            
          </HeadContainer>
          {/*
          <BottomContainer>
            <EndButton
              height={45}
              width={150}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <Typography text="show" fontSize="fz16" fontWeight="bold" />
                  &nbsp;
                  <Typography
                    text="all"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={() => {
                navigateToHandler('/library/listings');
              }}
            />
            <Spacer height={134} />
            </BottomContainer>
            */}
        </PageContainer>
      ) : (
        <>{!drops && hotDrops ? <ErrorPage /> : <></>}</>
      )}
    </>
  );
};

export default WithRouteAnimation(HotDropsPage);
